<template>
  <div class="crewing-registration">
    <title>{{ $t('crewingCompanyRegistration') }}</title>
    <div v-if="step === 4" class="crewing-registration__completion">
      <div class="crewing-registration__completion--title">{{ $t('sentCrewingCompanyRequest') }}</div>
      <div class="crewing-registration__completion--subtitle">
        Дані прийняті, результати розгляду заяви будуть вислані на адресу електронної пошти
        <a :href="`mailto:${signature.email}`">{{ signature.email }}</a>, вказану вами у формі.
      </div>
      <div class="crewing-registration__completion--button">
        <v-btn
          @click="$router.push({ name: 'authorization' })"
          :loading="isLoading"
          color="primary"
        >{{ $t('goToService') }}</v-btn>
      </div>
    </div>

    <div v-else class="crewing-registration__container">
      <h2 class="crewing-registration__container--title">{{ $t('crewingCompanyRegistration') }}</h2>

      <div class="crewing-registration__container--steppers">
        <div
          v-for="n in 3"
          :key="n"
          :class="{ 'active': n === step }"
          class="step"
        >
          {{ n }}
        </div>
      </div>

      <v-slide-x-transition>
        <div v-if="step === 1" class="crewing-registration__container--body">
          <h4 class="body-title">{{ $t('uploadActualStampEDS') }}</h4>
          <div class="body-container">
            <div class="w-100 pa-0">
              <FileDropZone ref="mediaFile" accept=".ZS2,.zs2,.JKS,.jks,.DAT,.dat" :multiple="false" />
            </div>

            <div class="w-100 pa-0 mt-4">
              <v-text-field
                v-model="stamp.password"
                :placeholder="$t('password')"
                type="password"
              />
            </div>
          </div>

          <div class="body-button">
            <v-btn
              @click="readKey('stamp')"
              :disabled="disabledNextBtn"
              :loading="isLoading"
              color="primary"
            >{{ $t('farther') }}</v-btn>
          </div>
        </div>

        <div v-else-if="step === 2" class="crewing-registration__container--body">
          <div class="body-arrow">
            <img @click="step -= 1" src="@/assets/img/arrow-left.svg" alt="arrow-left" />
          </div>
          <h4 class="body-title">{{ $t('uploadActualSignatureEDS') }}</h4>
          <div class="body-container">
            <div class="w-100 pa-0">
              <FileDropZone ref="mediaFile" accept=".ZS2,.zs2,.JKS,.jks,.DAT,.dat" :multiple="false" />
            </div>

            <div class="w-100 pa-0 mt-4">
              <v-text-field
                v-model="signature.password"
                :placeholder="$t('password')"
                type="password"
              />
            </div>
          </div>

          <div class="body-button">
            <v-btn
              @click="readKey('signature')"
              :disabled="disabledNextBtn"
              :loading="isLoading"
              color="primary"
            >{{ $t('farther') }}</v-btn>
          </div>
        </div>

        <div v-else-if="step === 3" class="crewing-registration__container--body">
          <div class="body-arrow">
            <img @click="step -= 1" src="@/assets/img/arrow-left.svg" alt="arrow-left" />
          </div>
          <h4 class="body-title">{{ $t('mainInfo') }}</h4>
          <div class="body-container">
            <div class="w-50 pl-0 pt-0">
              <v-text-field
                v-model="signature.director_full_name"
                onkeypress="return /[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ\s-']/i.test(event.key)"
                @blur="$v.signature.director_full_name.$touch()"
                :error-messages="$valid.validation($v.signature.director_full_name, ['required'])"
                :placeholder="$t('fullName')"
              />
            </div>

            <div class="w-50 pr-0">
              <v-text-field
                v-model="signature.email"
                @blur="$v.signature.email.$touch()"
                :error-messages="$valid.validation($v.signature.email, ['required'])"
                :placeholder="$t('email')"
              />
            </div>

            <div class="w-50 pl-0 pt-0">
              <v-text-field
                v-model="signature.phone"
                @blur="$v.signature.phone.$touch()"
                :error-messages="$valid.validation($v.signature.phone, ['required'])"
                :placeholder="$t('phoneNumber')"
                :hint="$t('phoneNumFormat')"
                type="number"
                persistent-hint
              />
            </div>

            <div class="w-50 pr-0">
              <v-text-field
                v-model="signature.address"
                @blur="$v.signature.address.$touch()"
                :error-messages="$valid.validation($v.signature.address, ['required'])"
                :placeholder="$t('address')"
              />
            </div>

            <div class="w-100 pa-0">
              <div class="fileName">{{ $t('abroadMediationLicense') }}</div>
              <FileDropZone ref="mediaFile" />
            </div>

            <div class="w-100 pa-0">
              <v-text-field
                v-model="signature.additional_info"
                :placeholder="$t('additionalInfo')"
              />
            </div>

            <div class="body-button agreementConsist">
              <v-checkbox
                v-model="agreement"
                :error-messages="agreementErrors"
                hide-details
                class="agreement mt-0"
              >
                <template #label>
                  <p>Я надаю свою <router-link :to="{ name: 'privacyPolicy' }" class="agreement--link">
                    Згоду на обробку персональних даних</router-link>,
                    <router-link :to="{ name: 'terms' }" class="agreement--link">Згоду з правилами порталу</router-link>
                    та ознайомлений з <router-link :to="{ name: 'licenseAgreement' }" class="agreement--link">Ліцензійним договором</router-link>.
                  </p>
                </template>
              </v-checkbox>

              <v-btn
                @click="checkFieldsValidation"
                :loading="isLoading"
                color="primary"
              >{{ $t('farther') }}</v-btn>
            </div>
          </div>
        </div>
      </v-slide-x-transition>
    </div>
  </div>
</template>

<script>
import FileDropZone from '@/components/atoms/DropZone/DropZone.vue'
import { required, email } from 'vuelidate/lib/validators'
import { EDSign } from '@ananasbear/eds'
import { mapState } from 'vuex'

export default {
  name: 'CrewingRegistration',
  components: {
    FileDropZone
  },
  data () {
    return {
      step: 1,
      EDS: null,
      stamp: {
        password: null,
        edrpou: null,
        director_full_name: null,
        phone: null,
        address: null,
        email: null,
        company_name: null
      },
      signature: {
        password: null,
        edrpou: null,
        director_full_name: null,
        phone: null,
        address: null,
        email: null,
        company_name: null,
        additional_info: null
      },
      agreement: true,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    mediaFiles () {
      return this.$refs.mediaFile.filesArray || []
    },
    disabledNextBtn () {
      if (this.step === 1) {
        return !this.stamp.password || !this.$refs.mediaFile ||
          (this.$refs.mediaFile.filesArray && !this.$refs.mediaFile.filesArray.length)
      } else {
        return !this.signature.password || !this.$refs.mediaFile ||
          (this.$refs.mediaFile.filesArray && !this.$refs.mediaFile.filesArray.length)
      }
    },
    agreementErrors () {
      const errors = []
      if (!this.$v.agreement.$dirty) return errors
      if (!this.$v.agreement.checked) errors.push('')
      return errors
    }
  },
  validations () {
    return {
      signature: {
        director_full_name: { required },
        phone: { required },
        address: { required },
        email: { required, email }
      },
      mediaFiles: { required },
      agreement: {
        checked (value) {
          return value
        }
      }
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'crewing-registration', access: false })
    this.$store.dispatch('getServers')
  },
  mounted () {
    this.EDS = new EDSign({ proxy: process.env.VUE_APP_CIFRA || 'https://api.cifra.com.ua/api/v1/proxy/' })
    this.EDS.initializationAsFile()
  },
  methods: {
    readKey (eds) {
      this.isLoading = true
      this.EDS.readFileKey(this.$refs.mediaFile.filesArray[0], this[eds].password).then(data => {
        this.EDS.getTypeKey().then(type => {
          this[eds].edrpou = data.subjEDRPOUCode
          this[eds].director_full_name = data.subjCN
          this[eds].phone = data.subjPhone
          this[eds].address = data.subjAddress ? `${data.subjLocality}, ${data.subjAddress}` : data.subjLocality
          this[eds].email = data.subjEMail
          this[eds].company_name = data.subjOrg

          if ((this.step === 1 && type === 'ST') || (this.step === 2 && type === 'SI' && this.stamp.edrpou === this.signature.edrpou)) {
            this.$refs.mediaFile.removeAllFile()
            this.step += 1
          } else this.$notification.error('invalidDataFormat')
        })
      }).finally(() => {
        this.isLoading = false
      })
    },

    checkFieldsValidation () { // Check fields validation
      if (this.$v.$invalid) this.$v.$touch()
      else this.addCrewingCompany()
    },

    addCrewingCompany () {
      this.isLoading = true
      this.$api.post('api/v1/public_api/statement_crewing/', { body: this.signature }).then(response => {
        if (response.code === 201) {
          const files = new FormData()
          files.append('id', response.data.id)
          for (const photo of this.mediaFiles) {
            files.append('photo', photo)
          }

          this.$api.post(`api/v1/crewing/statement_crewing/${response.data.id}/upload_file/`, { body: files, responseType: 'blob' }).then(response => {
            if ([200, 201].includes(response.code)) this.step = 4
          }).finally(() => {
            this.isLoading = false
          })
        } else this.isLoading = false
      })
    }
  }
}
</script>
